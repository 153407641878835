import React from 'react'
import Page from '../../components/page'
import withRoot from '../../withRoot'
import { isMobile } from "../../isMobile"
import { withStyles } from '@material-ui/core/styles'
import { navigate } from 'gatsby'
import {
  Typography,
  Button,
} from '@material-ui/core'
import permissions from '../../static/fitface-install/02.jpg'
import weatherios from '../../static/faq/fitbit-settings-ios-v2.png'
import weatherandroid from '../../static/faq/fitbit-settings-android.png'
import weatherandroidold from '../../static/faq/android-location-permission-old.png'
import weatherandroidnew from '../../static/faq/android-location-permission-new.png'
import weatherupdate from '../../static/faq/weatherupdate.png'

const styles = theme => ({
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  img: {
    margin: theme.spacing(2),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: '5px',
    maxWidth: isMobile() ? '100%' : '60%',
  },
})

@withRoot
@withStyles(styles)
export default class Weather extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <Page toggleTheme={this.props.toggleTheme} title="Weather update?">
        <Typography variant="h4">Weather is not updating on your watch?</Typography>
        <Typography className={classes.parag}>
          You need to configure your phone correctly to get weather update.
        </Typography>
        <Typography>
          If the weather is not updating correctly, please make sure you gave all the needed permissions during the install fo the clockface.<br />
          <img className={classes.img} src={permissions} />
          Also make sure the Fitbit app has always access to your location, you will need to enable the following on your mobile phone.<br />
          <Typography className={classes.section}>On <b>iPhone</b>, open Settings app → scroll down to Fitbit</Typography>
          <img className={classes.img} src={weatherios} />
          <Typography className={classes.section}>On <b>Android</b>, open Settings → Apps & Notifications → Fitbit</Typography>
          <img className={classes.img} src={weatherandroid} />
          <Typography className={classes.section}>Depending on your <b>Android</b> version, you should have something like the 2 screenshots below :</Typography>
          <img className={classes.img} src={weatherandroidold} />
          <img className={classes.img} src={weatherandroidnew} />
          With these settings enable, go back to your Fitbit app and allow the phone and watch to sync and your weather updates should work thereafter.<br />
          <Typography>
            You could also check on the period when the phone last pushed the weather data to the watch.<br />
            Go to your Fitbit app → Account → Clock Faces → Tap on watch → Settings → Enable the help tab<br />
            From there you could see when the weather was last updated.
          </Typography>
          <img className={classes.img} src={weatherupdate} />
          Contact us if you still have issues.
        </Typography>
        <Button onClick={() => navigate('/')}>Back to FAQ</Button>
      </Page>
    )
  }
}
